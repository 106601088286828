<script>
  /**
   * A button that represents a choice the user has to make to move to the next step.
   */
  export default {
    props: {
      /** The button’s type. Use `submit` when a button’s purpose is to submit a form. Note that a `submit` button must be inside a `BaseForm` component, and its `click` event is automatically triggered when the user presses Enter while an input of the form is focused. */
      type: {
        type: String,
        default: 'button',
        validator: (value) => ['button', 'submit'].includes(value),
      },
      /** Whether the button is disabled. A disabled button is grayed out and cannot be focused or clicked. */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <div>
    <button
      :type="type"
      :disabled="disabled"
      class="group button flex justify-between items-center w-full min-h-15 p-6 rounded-sm border bg-dawn font-semibold text-base text-left leading-tight transition active:transition-none duration-200"
      v-on="$listeners"
    >
      <span class="-my-1 block py-1 overflow-hidden ellipsis">
        <!-- @slot The button’s label. Should contain text and/or an image. -->
        <slot />
      </span>
      <BaseIcon
        :size="6"
        class="button-icon ml-2 -mr-2 flex-shrink-0 text-gray transition group-active:transition-none duration-200"
      >
        <IconChevronRight />
      </BaseIcon>
    </button>
  </div>
</template>

<style lang="postcss" scoped>
  .button {
    &:not(:disabled) {
      @apply border-gray-active;

      &:focus {
        @apply border-primary border-opacity-50;

        .button-icon {
          @apply text-primary text-opacity-50;
        }
      }

      &:hover {
        @apply border-primary;

        .button-icon {
          @apply text-primary;
        }
      }

      &:active {
        @apply border-transparent bg-primary-dark text-white;

        .button-icon {
          color: inherit;
        }
      }
    }

    &:disabled {
      @apply border-transparent bg-gray-lighter text-gray-dark cursor-default;
    }
  }
</style>
